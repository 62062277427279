import { TestResult, CategoryData, CategoryResult } from '../types'

import { getPassedTestsCount } from '../utils'

export function getOnlinePrivacyInitial(): CategoryData {
  return {
    title: 'Online Privacy',
    description:
      'This is a Browser Oriented test and it can easily be mitigated by turning this feature on. The Do Not Track option relies on technology to signal that the user doesn’t want to be tracked by companies and advertisers. This is however only an option and does not guarantee that the website is actually enforcing this action or ignoring it. The recommendation is to keep the feature active on all browsers.',
    tests: [],
    passedTestsCount: 0,
    generalTitle:
      'Large corporations are building their technologies in order to track',
    generalDescription:
      'Online Privacy is becoming a concern due to the fact that large corporations controlling most of the Internet applications and services are building their technologies in order to track, record and match every user and action for a multitude of purposes ranging from content delivery, to targeted advertising or statistics. ',
    businessTitle:
      'It is best to limit the amount of data that is provided via Browser',
    businessDescription:
      'Even if some activities on one website are not out of the ordinary, the collection of the same data on multiple services and later on the correlation of said data can identify much more than one could expect about an individual, or a business. For this reason it is best to limit the amount of data that is provided via Browser, online applications or mobile applications. Information that has been leaked can stay online forever.',
    impactTitle: ' The employees of your company may be subject to tracking',
    impactDescription:
      "The impact of Online Privacy can be the identification of users based on circumstantial data collected from other websites even if the user itself does not want to provide his identity. This is achieve through session cookies, telemetry, advertisment IDs and sometimes even by other small pieces of information of little value on their own. This means that the employees of your company may be subject to tracking and identification by either competitors, or other threat actors that can use the information to breach the employee's personal accounts and then move to corporate credentials.",
    article: {
      title: "If You Don't Care About Online Privacy, You Should Read This",
      summary:
        'Privacy is a strange issue in today’s world. People share their outrage on social media when news of a serious user privacy issue at a major tech company gets reported in the media, but there’s usually no acknowledgement of the irony associated with sharing this outrage on a platform that has likely had privacy issues of its own in recent months. At the end of the day, most people simply do not want to take the time to look beyond Google and Facebook to learn how they can better protect their personal data.',
      image: 'https://source.unsplash.com/0zRt0bQysMw/800x450',
      url: 'https://www.forbes.com/sites/ktorpey/2019/02/28/if-you-dont-care-about-online-privacy-you-should-read-this/',
      date: 'Feb 28, 2019',
    },
  }
}

export const getOnlinePrivacyResult = async (): Promise<CategoryResult> => {
  const tests = [checkDoNotTrack()]

  return {
    title: 'Online Privacy',
    tests,
    passedTestsCount: getPassedTestsCount(tests),
  }
}

/* ---------------------- 📡 Do Not Track setting check --------------------- */

function checkDoNotTrack(): TestResult {
  return {
    title: 'Do Not Track browser setting',
    description: 'DO_NOT_TRACK',
    passed: navigator.doNotTrack != null || window.doNotTrack != null,
  }
}
