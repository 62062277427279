import { CategoryData, CategoryResult, TestResult } from '../types'

import { getPassedTestsCount } from '../utils'

/* -------------------------- 💼 Category functions ------------------------- */

export const getBrowserSecurityInitial = (): CategoryData => ({
  title: 'Browser Security',
  description:
    'This set of tests is aimed at Browser Specific configuration and add-ons.',
  generalTitle: 'Protection for information related to the sender and receiver',
  tests: [],
  passedTestsCount: 0,
  generalDescription:
    'Browser Security is an application of internet security to web browsers to protect data in transit and information related to the sender and receiver of the data from intended audiences, breaches of privacy or malware.',
  businessTitle:
    'Phishing, cross-site scripting or links to malicious locations',
  businessDescription:
    'If business use applications that are not secured then this can open up attack vectors through phishing, cross-site scripting or even plain links to malicious locations.',
  impactTitle: ' Most used ways of infecting a host',
  impactDescription:
    'If the browser does not use correct criptography, does not check for certificate validity and trustworthiness and does not check code running on websites for malicious actions then this may be one of the most often used ways of infecting a host.',
  article: {
    title: 'Securing Your Web Browser',
    summary:
      'Ideally, computer users should evaluate the risks from the software they use. Many computers are sold with software already loaded. Whether installed by a computer manufacturer, operating system maker, Internet service provider, or by a retail store, the first step in assessing the vulnerability of your computer is to find out what software is installed and how programs will interact with each other.',
    image: 'https://source.unsplash.com/9SoCnyQmkzI/800x450',
    url: 'https://us-cert.cisa.gov/publications/securing-your-web-browser',
    date: 'Sep 8, 2015',
  },
})

export const getBrowserSecurityResult = async (): Promise<CategoryResult> => {
  const tests = await Promise.all([checkEnabledPopups()])

  return {
    title: 'Browser Security',
    tests,
    passedTestsCount: getPassedTestsCount(tests),
  }
}

/* ---------------------- 📄 Individual tests functions --------------------- */

function checkEnabledPopups(): TestResult {
  const testDetails: TestResult = {
    title: 'Disabled Popups',
    description: 'DISABLED_POPUPS',
    passed: true,
  }

  let popup = window.open('about:blank', 'new_window', 'height=150, width=150')

  const isBlocked =
    !popup || popup.closed || popup.closed === undefined || popup === undefined

  popup && popup.close()

  if (isBlocked) {
    return testDetails
  }

  return { ...testDetails, passed: false }
}
