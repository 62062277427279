import axios from 'axios'
import md5 from 'md5'

import {
  TestResult,
  EicarFileDetails,
  CategoryData,
  CategoryResult,
} from '../types'

import { getPassedTestsCount } from '../utils'

/* ------------------------------ 🗿 Constants ------------------------------ */

const files: EicarFileDetails[] = [
  {
    name: 'eicar.com',
    md5: '44d88612fea8a8f36de82e1278abb02f',
    title: 'malware file',
    description: 'PLAIN_MALWARE'
  },
  {
    name: 'eicar.com.txt',
    md5: '44d88612fea8a8f36de82e1278abb02f',
    title: 'malware (text file)',
    description: 'TEXT_MALWARE'
  },
  {
    name: 'eicar_com.zip',
    md5: '8b5df892ade61a2192b1c6f29ec9ea10',
    title: 'malware (file inside zip)',
    description: 'ZIP_MALWARE'
  },
  {
    name: 'eicar_com2.zip',
    md5: 'e9f453e237328077f010dae382631372',
    title: 'malware (file zipped 2 times)',
    description: 'ZIP_2_MALWARE'
  },
  {
    name: 'eicar_com5.zip',
    md5: '994081c08a102f6116d493e20ba56e92',
    title: 'malware (file zipped 5 times)',
    description: 'ZIP_5_MALWARE'
  },
  {
    name: 'eicar_com123456.zip',
    md5: '5c476953de24262019cee58c7430a48a',
    title: "malware (file zipped with '123456' password)",
    description: 'ZIP_PASS_MALWARE'
  },
  {
    name: 'eicar_compassword.zip',
    md5: '977c83aae83cbd92d5927d2933ee5faa',
    title: "malware (file zipped with 'password' password)",
    description: 'ZIP_PASS_MALWARE'
  },
]

/* -------------------------- 💼 Category functions ------------------------- */

export function getMalwareProtectionInitial(): CategoryData {
  return {
    title: 'Malware Protection',
    description:
      'Malware is one of the traditional culprits in many cyber security incidents and many times it leads to loss of data, loss of access to systems or even converts your system into a remote-controlled bot.',
    tests: [],
    passedTestsCount: 0,
    generalTitle: 'A piece of compiled code designed to infect systems',
    generalDescription:
      "Malware, in it's original form Malicious Software is a piece of compiled code designed to infect systems and produce damage, deny access or steal information. Malware may be of different types: viruses, worms, spyware, trojans and others.",
    businessTitle:
      'Disruption of business, competitive data theft or ransomware',
    businessDescription:
      'Businesses rely on computer systems for a multitude of tasks, be it communication, document processing, invoicing, media processing and projection and others. Malware is designed to disrupt such activities and prevent the normal operation of computer systems and networks. The reasons behind existence of malware are diverse but the end result is the same - temporary disruption of business or even worse competitive data theft and ransomware.',
    impactTitle: 'Deletion of files or permanent hardware damage',
    impactDescription:
      'Once systems are infected by malware the symptoms may range from: overuse of processing power and memory, deletion of files, loss of access to data or even permanent hardware damage.',
    article: {
      title: 'Inside The Aftermath Of The Saudi Aramco Breach',
      summary:
        'Three years ago, malware partially wiped or totally destroyed the hard drives of 35,000 Aramco computers. Saudi Aramco employees first noticed something was wrong on Aug. 15, 2012, as files disappeared and computers started to fail. A group calling itself the Cutting Sword of Justice claimed responsibility for the attack, which lasted just a few hours. The IT staff immediately disconnected all the systems and the data centers to stop the malware. Every office was physically unplugged from the Internet, taking the company offline and isolating it from the rest of the world.',
      image: 'https://source.unsplash.com/Skf7HxARcoc/800x450',
      url:
        'https://www.darkreading.com/attacks-breaches/inside-the-aftermath-of-the-saudi-aramco-breach/d/d-id/1321676',
      date: 'Aug 8, 2015',
    },
  }
}

export const getMalwareProtectionResult = async (
  host: string,
  isRunningViaHTTPS: boolean
): Promise<CategoryResult> => {
  let tests = []
  if (isRunningViaHTTPS) {
    tests = await Promise.all(
      files.flatMap((file) => [getMalwareFile('https', host, file)])
    )
  } else {
    tests = await Promise.all(
      files.flatMap((file) => [
        getMalwareFile('http', host, file),
        getMalwareFile('https', host, file),
      ])
    )
  }

  return {
    title: 'Malware Protection',
    tests,
    passedTestsCount: getPassedTestsCount(tests),
  }
}

/* --------------------------- 📄 Tests functions --------------------------- */

async function getMalwareFile(
  protocol: 'http' | 'https',
  host: string,
  file: EicarFileDetails
): Promise<TestResult> {
  const testResult = {
    title: `Download ${file.title} via ${protocol}`,
    description: file.description,
    passed: true,
  }

  try {
    const response = await axios.get(
      `${protocol}://${host}/eicar-files/${file.name}`,
      { timeout: 15000 }
    )

    if (md5(response.data) !== file.md5) {
      return testResult
    }

    return { ...testResult, passed: false }
  } catch (e) {
    return testResult
  }
}
