import { getMalwareProtectionInitial } from './MalwareProtection'
import { getDataProtectionInitial } from './DataProtection'
import { getOnlinePrivacyInitial } from './OnlinePrivacy'
import { getDataEncryptionInitial } from './DataEncryption'
import { getBrowserSecurityInitial } from './BrowserSecurity'
import { getNetworkAccessProtectionInitial } from './NetworkAccessProtection'

import { SecurityCheckData } from './types'

export const getCategoriesDescriptions = (): SecurityCheckData => [
  getMalwareProtectionInitial(),
  getDataProtectionInitial(),
  getOnlinePrivacyInitial(),
  getDataEncryptionInitial(),
  getBrowserSecurityInitial(),
  getNetworkAccessProtectionInitial(),
]

export { getMalwareProtectionResult } from './MalwareProtection'
export { getDataProtectionResult } from './DataProtection'
export { getOnlinePrivacyResult } from './OnlinePrivacy'
export { getDataEncryptionResult } from './DataEncryption'
export { getBrowserSecurityResult } from './BrowserSecurity'
export { getNetworkAccessProtectionResult } from './NetworkAccessProtection'
