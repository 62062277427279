import axios from 'axios'

import {
  TestResult,
  CategoryData,
  CategoryResult,
  SQLInjectionPayload
} from '../types'

import { getPassedTestsCount } from '../utils'

declare global {
  interface Window { CRLT: any }
}

window.CRLT = window.CRLT || undefined

/* ------------------------------ 🗿 Constants ------------------------------ */

const SQLPayloads: string[] = [
  "' OR '1'='1' -- ",
  "admin' UNION SELECT table_name, column_name, 1 FROM information_schema.columns -- ",
  "admin' U/**/NIO/**/N/**/ SELEC/**/T table_name, column_name, 1 FR/**/OM information_schema.columns -- ",
  "admin' 'UNI'+'ON SE'+'LECT' table_name, column_name, 1 FR/**/OM information_schema.columns -- ",
  "CONCAT(CHAR(65),CHAR(68),CHAR(77),CHAR(73),CHAR(78),CHAR(39))' -- ", // eq to "admin' -- "
]

const XSS = '<script type="text/javascript" language="javascript">alert("1")</script>'

/* -------------------------- 💼 Category functions ------------------------- */

export const getNetworkAccessProtectionInitial = (): CategoryData => ({
  title: 'Network Access Protection',
  description:
    'Network Security is an important part of the overall defence strategy and should be properly configured and enforced. The tests below are aimed at Next Generation Firewall features and Secure Web Gateways.',
  tests: [],
  passedTestsCount: 0,
  generalTitle:
    'The ability of a network to control where users are allowed to go',
  generalDescription:
    'Network Access Protection is the ability of a network to control where users are allowed to go and what they are allowed to access. ',
  businessTitle:
    'A way to prevent employees them from getting infected with malware',
  businessDescription:
    'Many times there are locations which pose a higher risk of hosting malicious code or links to malicious websites. Blocking of certain web categories can be not only a way to keep your employees focused on work but a way to prevent them from getting infected with malware.',
  impactTitle:
    'Users may land on malicious websites or click on malicious links',
  impactDescription:
    'The lack of control on which websites are accessed is a sign that this cannot be controlled and that users may land on malicious websites or click on malicious links and have their computers infected or credentials stolen through phishing. ',
  article: {
    title: 'Growing VPN Exploitation Is Cause For Concern',
    summary:
      'Conventional wisdom says that initial access is often a phishing attack, and that is often the case, but there is a growing trend where compromise of the remote access device is the initial point of compromise.',
    image: 'https://source.unsplash.com/M5tzZtFCOfs/800x450',
    url:
      'https://www.infosecurity-magazine.com/blogs/vpn-exploitation-concern/',
    date: 'Mar 11, 2020',
  },
})

export const getNetworkAccessProtectionResult = async (
  host: string,
  isRunningViaHTTPS: boolean
): Promise<CategoryResult> => {
  let tests = []
  if (isRunningViaHTTPS) {
    tests = await Promise.all([
      checkAdultContentBlock(),
      checkEmbargoedCountriesBlock(),
      checkAnonymizingWebsitesBlock(),
      runJSMiner(),
      checkXSSBlock('https', host),
      checkSQLInjectionBlock('https', host)
    ])
  } else {
    tests = await Promise.all([
      checkAdultContentBlock(),
      checkEmbargoedCountriesBlock(),
      checkAnonymizingWebsitesBlock(),
      runJSMiner(),
      checkXSSBlock('http', host),
      checkXSSBlock('https', host),
      checkSQLInjectionBlock('http', host),
      checkSQLInjectionBlock('https', host)
    ])
  }

  return {
    title: 'Network Access Protection',
    tests,
    passedTestsCount: getPassedTestsCount(tests),
  }
}

/* --------------------------- 📄 Tests functions --------------------------- */

async function checkAdultContentBlock(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Block access to adult websites',
    description: 'ADULT',
    passed: true,
  }

  if (localStorage.getItem('FP_TESTCAFE')) {
    return { ...testDetails, passed: false }
  }

  try {
    await appendImage(
      'https://static-hw.xvideos.com/v3/img/skins/default/favicon.ico'
    )
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, passed: false }
}

async function checkEmbargoedCountriesBlock(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Block websites in embargoed countries',
    description: 'EMBARGOED',
    passed: true,
  }

  if (localStorage.getItem('FP_TESTCAFE')) {
    return { ...testDetails, passed: false }
  }

  try {
    await appendImage(
      'https://shaparak.ir/front-assets/images/top_logo.jpg'
    )
    return { ...testDetails, passed: false }
  } catch (e) {
    return testDetails
  }
}

async function checkAnonymizingWebsitesBlock(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Block access to anonymizing websites',
    description: 'ANONYMIZING',
    passed: true,
  }

  if (localStorage.getItem('FP_TESTCAFE')) {
    return { ...testDetails, passed: false }
  }

  try {
    await appendImage('https://anonfox.com/favicon.ico')
    return { ...testDetails, passed: false }
  } catch (e) {
    return testDetails
  }
}

async function checkSQLInjectionBlock(
  protocol: 'http' | 'https',
  host: string
): Promise<TestResult> {
  const promises = SQLPayloads.map(async (payload) => {
    const subtestResult: TestResult = {
      title: payload.substring(0, 32),
      description: 'n/a',
      passed: true,
    }

    if (localStorage.getItem('FP_TESTCAFE')) {
      return { ...subtestResult, passed: false }
    }

    try {
      const object = JSON.stringify(payloadToObject(payload))

      await axios.get(
        encodeURI(`${protocol}://${host}/api/echo/?${object}`),
        { timeout: 15000 }
      )

      return { ...subtestResult, passed: false }
    } catch (e) {
      return subtestResult
    }
  })

  const subtests = await Promise.all(promises)

  return {
    title: `SQL Injection in GET request via ${protocol}`,
    description: 'SQLI',
    passed: !subtests.some((subtest) => !subtest.passed),
    subtests,
  }
}

async function checkXSSBlock(
  protocol: 'http' | 'https',
  host: string
): Promise<TestResult> {
  const testDetails: TestResult = {
    title: `Cross-Site Scripting Scanning Attempt via ${protocol}`,
    description: 'XSS',
    passed: true,
  }

  if (localStorage.getItem('FP_TESTCAFE')) {
    return { ...testDetails, passed: false }
  }

  try {
    await axios.get(
      encodeURI(`${protocol}://${host}/api/echo/?t=${XSS}`),
      { timeout: 15000 }
    )

    return { ...testDetails, passed: false }
  } catch (e) {
    return testDetails
  }
}

async function runJSMiner(): Promise<TestResult> {
  const subtestResult: TestResult = {
    title: 'Block JavaScript cryptominer',
    description: 'MINER',
    passed: true,
  }

  if (localStorage.getItem('FP_TESTCAFE')) {
    return { ...subtestResult, passed: false }
  }

  try {
    const result: boolean = await new Promise((resolve, reject) => {
      let scriptElement = document.createElement('script')

      scriptElement.src = 'https://www.hashing.win/scripts/min.js'
      document.head.appendChild(scriptElement)

      scriptElement.onload = () => {
        resolve(false)
      }
      scriptElement.onerror = () => {
        reject(true)
      }

      setTimeout(() => {
        if ( !scriptElement) {
          reject(true)
        }
      },
        5000
      )
    })

    return { ...subtestResult, passed: result}
  } catch (e) {
    return subtestResult
  }
}

/* ---------------------------- 🔧 Util functions --------------------------- */

const payloadToObject = (payload: string): SQLInjectionPayload => ({
  username: payload,
  password: 'aaa',
})

async function appendImage(url: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let image = document.createElement('img')
    image.width = 0
    image.src = url
    image.onload = () => {
      resolve(false)
    }
    image.onerror = () => {
      reject(true)
    }
    setTimeout(() => {
      if ( !image.complete || !image.naturalWidth ) {
        reject(true)
      }
    },
      5000
    )
  })
}
