import React from 'react'
import './App.css'

import {
  getBrowserSecurityResult, 
  getDataEncryptionResult, 
  getNetworkAccessProtectionResult,
  getDataProtectionResult,
  getOnlinePrivacyResult,
  getMalwareProtectionResult
} from './services'
import { TestResult } from './services/types'

export interface IAppState {
  browserSecurity: TestResult[] | null
  networkAccessProtection: TestResult[] | null
  dataEncryption: TestResult[] | null
  onlinePrivacy: TestResult[] | null
  malwareProtection: TestResult[] | null
  dataProtection: TestResult[] | null
}

export default class App extends React.Component<{}, IAppState> {
  state = {
    browserSecurity: null,
    networkAccessProtection: null,
    dataEncryption: null,
    onlinePrivacy: null,
    malwareProtection: null,
    dataProtection: null
  }

  runAll = async () => {
    this.runBrowserSecurity()
    this.runNetworkAccess()
    this.runDataEncryption()
    this.runOnlinePrivacy()
    this.runMalwareProtection()
    this.runDataProtection()
  }

  componentDidMount() {
    // const a = getCategoriesDescriptions()
    // console.log(a)
  }

  runBrowserSecurity = async () => {
    this.setState({ browserSecurity: [] })
    const browserSecurity = await getBrowserSecurityResult()
    console.log(browserSecurity)
    this.setState({ browserSecurity: browserSecurity.tests })
  }

  runNetworkAccess = async () => {
    this.setState({ networkAccessProtection: [] })
    const a = await getNetworkAccessProtectionResult('int.codacloud.net', true)
    this.setState({ networkAccessProtection: a.tests })
  }

  runDataEncryption = async () => {
    this.setState({ dataEncryption: [] })
    const dataEncryption = await getDataEncryptionResult()
    this.setState({ dataEncryption: dataEncryption.tests })
  }

  runOnlinePrivacy = async () => {
    this.setState({ onlinePrivacy: [] })
    const onlinePrivacy = await getOnlinePrivacyResult()
    this.setState({ onlinePrivacy: onlinePrivacy.tests })
  }

  runMalwareProtection = async () => {
    this.setState({ malwareProtection: [] })
    const malwareProtection = await getMalwareProtectionResult('int.codacloud.net', true)
    this.setState({ malwareProtection: malwareProtection.tests })
  }

  runDataProtection = async () => {
    this.setState({ dataProtection: [] })
    const dataProtection = await getDataProtectionResult('int.codacloud.net', true)
    this.setState({ dataProtection: dataProtection.tests })
  }

  getTests(category: TestResult[] | null) {
    if (!category) {
      return null
    }

    return category.map((item, key) => (
      <div className='Category' key={key}>
        <p>{item.title}</p>
        <p>{item.passed ? 'Passed' : 'Failed'}</p>
      </div>
    ))
  }

  render() {
    return (
      <div className="App" >
        <h1>Test Categories</h1>
        <button onClick={this.runAll}>Run All Tests</button>
        <div className='Title'>
          <h2>Browser Security</h2>
          <button onClick={this.runBrowserSecurity}>Run Test</button>
        </div>
        {this.getTests(this.state.browserSecurity)}
        <div className='Title'>
          <h2>Network Access Protection</h2>
          <button onClick={this.runNetworkAccess}>Run Test</button>
        </div>
        {this.getTests(this.state.networkAccessProtection)}
        <div className='Title'>
          <h2>Data Encryption</h2>
          <button onClick={this.runDataEncryption}>Run Test</button>
        </div>
        {this.getTests(this.state.dataEncryption)}
        <div className='Title'>
          <h2>Online Privacy</h2>
          <button onClick={this.runOnlinePrivacy}>Run Test</button>
        </div>
        {this.getTests(this.state.onlinePrivacy)}
        <div className='Title'>
          <h2>Malware Protection</h2>
          <button onClick={this.runMalwareProtection}>Run Test</button>
        </div>
        {this.getTests(this.state.malwareProtection)}
        <div className='Title'>
          <h2>Data Protection</h2>
          <button onClick={this.runDataProtection}>Run Test</button>
        </div>
        {this.getTests(this.state.dataProtection)}
      </div>
    )
  }
}
