import { TestResult, CategoryResult, CategoryData } from '../types'

import { getPassedTestsCount } from '../utils'

/* ---------------------------- 🔧 Util functions --------------------------- */

function getUrl(domain: string): string {
  return `https://${domain}.badssl.com/test/dashboard/small-image.png`
}

/* -------------------------- 💼 Category functions ------------------------- */

export function getDataEncryptionInitial(): CategoryData {
  return {
    title: 'Data Encryption',
    description:
      'Data security in transit is a serious concern especially over the World Wide Web. You can never be sure of what path the communciation takes and who is controlling that equipment. As a conclusion end-to-end encryption is a must have!',
    tests: [],
    passedTestsCount: 0,
    generalTitle:
      'Data Encryption is an important line of defense in cyber security',
    generalDescription:
      'Data Encryption is a method to protect information by encoding it either in storage or in transit. Ecrypted data can only be read/decrypted by having the correct encryption key. It is an important line of defense in cyber security as it deters access to information through malicious intent or negligence.',
    businessTitle: 'Data has to also be protected in case of unintended access',
    businessDescription:
      'Businesses keep all their valuable information about customers, transactions, source code or action plans in the form of computer data. Beside protecting the information systems themselves the data has to also be protected in case of unintended access. Regardless if the access is obtain through malicious actions or pure neglicence having the data encrypted may render it useless without the correct encryption key so the breach can also be mitigated.',
    impactTitle: 'Not encrypted data can be read in transit by other parties',
    impactDescription:
      'When data is not encrypted the risk is that it can be read in transit by other parties or even hackers and then used in unintended ways. ',
    article: {
      title: 'Over Two-Thirds of Q1 Malware Hidden by HTTPS',
      summary:
        'Some 67% of that malware was delivered via HTTPS connections and 72% of these encrypted attacks apparently featured zero-day malware which would have been missed by legacy signature-based AV. The growing popularity of HTTPS is down in part to initiatives like Let’s Encrypt, backed by the non-profit Internet Security Research Group (ISRG). However, while it has improved website security and user privacy, it also offers cyber-criminals a free and easy way to disguise their activity.',
      image: 'https://source.unsplash.com/iar-afB0QQw/800x450',
      url:
        'https://www.infosecurity-magazine.com/news/over-twothirds-of-q1-malware/',
      date: 'Jun 8, 2020',
    },
  }
}

export const getDataEncryptionResult = async (): Promise<CategoryResult> => {
  const tests = await Promise.all([
    checkCertValidation(),
    checkBrokenCryptography(),
    checkDomainSecurityPolicies(),
  ])

  return {
    title: 'Data Encryption',
    tests,
    passedTestsCount: getPassedTestsCount(tests),
  }
}

/* ------------------- 📄 Certificate Validation functions ------------------ */

async function checkCertValidation(): Promise<TestResult> {
  const subtests = await Promise.all([
    checkExpiredCert(),
    checkWrongHostCert(),
    checkSelfSignedCert(),
    checkUntrustedRootSignedCert(),
  ])

  const testDetails: TestResult = {
    title: 'Certificate Validation',
    description: 'CERTIFICATE_VALIDATION',
    passed: !subtests.some((subtest) => subtest.passed === false),
    subtests,
  }

  return testDetails
}

async function checkExpiredCert(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Expired Certificate',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('expired'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkWrongHostCert(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Wrong Host',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('wrong.host'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkSelfSignedCert(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Self Signed Certificate',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('self-signed'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkUntrustedRootSignedCert(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Untrusted Root',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('untrusted-root'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

/* -------------------- 🔢 Broken Cryptography functions -------------------- */

async function checkBrokenCryptography(): Promise<TestResult> {
  const subtests = await Promise.all([
    checkWeakSignature(),
    checkMismatchRC4(),
    checkMismatchDH512(),
    checkMismatchDH1024(),
  ])

  const testDetails: TestResult = {
    title: 'Broken Cryptography',
    description: 'BROKEN_CRYPTOGRAPHY',
    passed: !subtests.some((subtest) => subtest.passed === false),
    subtests,
  }

  return testDetails
}

async function checkWeakSignature(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Weak Certificate Signature',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('sha1-intermediate'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkMismatchRC4(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Cipher Mismatch - RC4',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('rc4'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkMismatchDH512(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Cipher Mismatch - DH512',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('dh512'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkMismatchDH1024(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Cipher Mismatch - DH1024',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('dh1024'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

/* ------------------ 🔒 Domain Security Policies functions ----------------- */

async function checkDomainSecurityPolicies(): Promise<TestResult> {
  const subtests = await Promise.all([
    checkRevokedCert(),
    checkKeyNotInCertChain(),
    checkCertSymantecLegacy(),
    checkCertTransparency(),
  ])

  const testDetails: TestResult = {
    title: 'Domain Security Policies',
    description: 'DOMAIN_SECURITY_POLICIES',
    passed: !subtests.some((subtest) => subtest.passed === false),
    subtests,
  }

  return testDetails
}

async function checkRevokedCert(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'Revoked Certificate',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('revoked'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkKeyNotInCertChain(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'NET::ERR_SSL_PINNED_KEY_NOT_IN_CERT_CHAIN',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('pinning-test'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkCertSymantecLegacy(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'NET::ERR_CERT_SYMANTEC_LEGACY',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('invalid-expected-sct'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function checkCertTransparency(): Promise<TestResult> {
  const testDetails: TestResult = {
    title: 'NET::ERR_CERTIFICATE_TRANSPARENCY_REQUIRED',
    description: 'CANNOT_CONNECT',
    passed: true,
  }

  try {
    await appendImage(getUrl('no-sct'))
  } catch (e) {
    return testDetails
  }

  return { ...testDetails, description: 'CONNECTED', passed: false }
}

async function appendImage(url: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let image = document.createElement('img')
    image.width = 0
    image.src = url
    image.onload = () => {
      resolve(false)
    }
    image.onerror = () => {
      reject(true)
    }
  })
}
